import useOnClickOutside from '@/hooks/useOnClickOutside/useOnClickOutside';
import nextId from '@uikit/helpers/nextId';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import FormField from '../FormField/FormField';
import type { FormFieldElement } from '../FormField/interfaces';
import styles from './FormFieldSuggestion.module.scss';
import type { FormFieldSuggestionProps } from './interfaces';

const getComboBoxId = () => `combobox-${nextId()}`;
const getListBoxId = () => `listbox-${nextId()}`;
const getLabelId = () => `label-${nextId()}`;

const FormFieldSuggestion = React.forwardRef(
  (
    {
      options,
      nativeOptions,
      onSelectOption,
      inputId,
      isBoxOnGradient = false,
      errorMessageHasRelativePosition = false,
      selectOnly,
      disableNativeSelect = false,
      onChange,
      onFocus,
      ...props
    }: FormFieldSuggestionProps,
    ref
  ) => {
    const comboBoxId = useRef(getComboBoxId()).current;
    const listBoxId = useRef(getListBoxId()).current;
    const labelId = useRef(getLabelId()).current;
    const [selectedOption, setSelectedOption] = useState(-1);
    const [optionsVisible, setOptionsVisible] = useState(false);
    const internRef = useRef<HTMLDivElement>(null);
    const ariaActiveDescendant =
      selectedOption === -1 ? '' : `${listBoxId}-${selectedOption}`;

    const onNativeSelectOption = (
      $event: React.ChangeEvent<HTMLSelectElement>
    ) => {
      setSelectedOption(
        nativeOptions.findIndex((opt) => opt.value === $event.target.value)
      );

      onSelectOption(
        nativeOptions.find((opt) => opt.value === $event.target.value)!
      );
    };

    const handleChange = ($event: React.ChangeEvent<FormFieldElement>) => {
      setOptionsVisible(true);
      onChange?.($event);
      setSelectedOption(-1);
    };

    const handleOnClickOption = (index: number) => () => {
      setSelectedOption(index);
      onSelectOption(options[index]);
      setOptionsVisible(false);
    };

    const handleKeyDown = ($event: React.KeyboardEvent<FormFieldElement>) => {
      switch ($event.key) {
        case 'Enter':
          if (options[selectedOption]) {
            onSelectOption(options[selectedOption]);
          }
          $event.preventDefault();
          break;
        case 'ArrowDown':
          if (options.length !== 0) {
            setSelectedOption((selectedOption + 1) % options.length);
          }
          $event.preventDefault();
          break;
        case 'ArrowUp':
          setSelectedOption(
            (selectedOption - 1 + options.length) % options.length
          );
          $event.preventDefault();
          break;
        case 'Home':
          setSelectedOption(0);
          $event.preventDefault();
          break;
        case 'End':
          setSelectedOption(options.length - 1);
          $event.preventDefault();
          break;
        case 'Escape':
          setOptionsVisible(false);
          $event.preventDefault();
          $event.stopPropagation();
          break;
        case 'Tab':
          if (options[selectedOption]) {
            onSelectOption(options[selectedOption]);
          }
          setOptionsVisible(false);
          break;
        default:
          break;
      }
    };

    const handleFocus = ($event: React.FocusEvent<FormFieldElement>) => {
      setOptionsVisible(true);
      onFocus?.($event);
    };

    useOnClickOutside(internRef, () => setOptionsVisible(false));

    return (
      <div className={clsx(styles.base)} ref={internRef || ref}>
        <div
          className={styles.inputBox}
          role="combobox"
          aria-expanded={optionsVisible}
          aria-controls={listBoxId}
          aria-owns={listBoxId}
          aria-haspopup="listbox"
          id={comboBoxId}
        >
          <FormField
            isBoxOnGradient={isBoxOnGradient}
            errorMessageHasRelativePosition={errorMessageHasRelativePosition}
            className={clsx(styles.input, {
              [styles.selectOnly]: selectOnly,
            })}
            onKeyDown={handleKeyDown}
            id={inputId}
            {...props}
            aria-activedescendant={ariaActiveDescendant}
            aria-autocomplete="list"
            aria-controls={listBoxId}
            autoComplete="off"
            labelId={labelId}
            onChange={handleChange}
            onFocus={handleFocus}
            withOutAutoFocus
          />
        </div>
        {!disableNativeSelect && (
          <select
            className={clsx(styles.nativeSelect, {
              [styles.selectOnly]: selectOnly,
            })}
            value={
              nativeOptions.length &&
              selectedOption >= 0 &&
              nativeOptions[selectedOption]
                ? nativeOptions[selectedOption].value
                : 'please-choose'
            }
            onClick={props.onClickIcon}
            onChange={($event) => onNativeSelectOption($event)}
            id="combobox-suggestions"
          >
            <option value="please-choose" disabled>
              Bitte wählen
            </option>
            {nativeOptions.map((option, idx) => (
              <option key={idx} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}

        {!!options.length && optionsVisible && (
          <ul
            className={clsx(styles.list, {
              [styles.disableNativeSelect]: disableNativeSelect,
            })}
            aria-labelledby={labelId}
            role="listbox"
            id={listBoxId}
          >
            {options.map((option, idx) => (
              <li
                className={styles.listItem}
                onClick={handleOnClickOption(idx)}
                key={option.value}
                role="option"
                aria-selected={idx === selectedOption}
                id={`${listBoxId}-${idx}`}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
);

FormFieldSuggestion.displayName = 'FormFieldSuggestion';

export default FormFieldSuggestion;
